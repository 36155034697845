<template>
  <v-theme-provider dark>
    <base-section
      id="zitat5"
      class="primary"
      space="36"
    >
      <v-row
        align="center"
        class="ma-0"
        justify="center"
      >
        <base-title
          space="0"
          title="Die Gelassenheit ist eine anmutige Form des Selbstbewusstseins."
        />
      </v-row>
      <v-row
        align="center"
        class="ma-0"
        justify="center"
      >
        <base-subtitle
          class="subtitle"
          space="0"
          title="Marie von Ebner-Eschenbach"
        />
      </v-row>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionZitat5',
  }
</script>

<style scoped>
.subtitle {
  color: white !important;
}
</style>
